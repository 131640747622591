@charset "UTF-8";
body {
  margin: 0;
  overflow-x: hidden;
  width: 100%;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
}
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
:focus {
  outline: 0;
}
.isfooter[data-v-bf982cc4] {
  background: #383838;
  width: 100%;
  height: 200px;
}
.isfooter li[data-v-bf982cc4] {
  list-style-type: none;
  padding: 10px 0px;
}
.isfooter .hezuo[data-v-bf982cc4] {
  display: inline-block;
  padding: 10px;
}
.isfooter a[data-v-bf982cc4] {
  color: #fff;
  text-decoration: none;
}
.isHead[data-v-6a360216] {
  background: #ff4949;
  height: 60px;
}
.isHead a[data-v-6a360216] {
  font-weight: bold;
  color: #2c3e50;
}
.isHead a.router-link-exact-active[data-v-6a360216] {
  color: #42b983;
}
.el-menu-item[data-v-6a360216] {
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
}
.el-menu-item[data-v-6a360216] :hover {
  border-bottom: solid 12px #e6e6e6;
}
.el-menu.el-menu--horizontal[data-v-6a360216] {
  border-bottom: solid 0px #e6e6e6;
}
.el-dropdown-menu__item[data-v-6a360216]:focus,
.el-dropdown-menu__item[data-v-6a360216]:not(.is-disabled):hover {
  background: #ff4949;
  color: #fff;
}
.ispcli[data-v-6a360216] {
  list-style-type: none;
  float: left;
  line-height: 60px;
  margin-top: -20px;
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  height: 62px;
  padding: 0px 15px;
  text-align: center;
  color: #fff;
}
.ispcli a[data-v-6a360216] {
  color: #fff;
  text-decoration: none;
}
.ispcli[data-v-6a360216]:hover {
  border-bottom: solid 2px #e6e6e6;
  background: #bd3737;
}
.isIndex {
  font-family: "Helvetica", sans-serif;
}
.isIndex .el-button {
  border-radius: 0px;
}
.isIndex .magin0 {
  margin: 0px;
}
.isIndex .row-bg {
  margin-left: 0px;
  margin-right: 0px;
}
.isIndex .siziimg {
  position: absolute;
  top: 25%;
  width: 100%;
}
.isIndex .siziimg img {
  margin: 0 auto;
  max-width: 100%;
}
.isIndex .gamstetit {
  width: 100%;
  margin: 2rem 0px;
}
.isIndex .gamstetit .en {
  font-size: 1rem;
  color: #101010;
  font-family: "Helvetica", sans-serif;
}
.isIndex .gamstetit .zh {
  font-size: 1.5rem;
  color: #101010;
  font-family: "FZLTCHJW", sans-serif;
  font-weight: bold;
}
.isIndex .isgemscent {
  background: #e7e7e7;
}
.isIndex .isNews {
  background: #eeeeee;
}
.isIndex .isbj2 {
  margin-top: 50px;
  width: 100%;
}
.isIndex .triangle_left {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 10rem solid #ff3f40;
  border-bottom: 0px solid transparent;
}
.isIndex .swiper-button-prev_q {
  background: url(../../src/img/193.892de879.png) center center no-repeat;
  left: -30px;
  right: auto;
  background-size: cover;
  width: 80px;
  height: 80px;
}
.isIndex .swiper-button-next {
  background: url(../../src/img/194.be21fe69.png) center center no-repeat;
  right: -30px;
  left: auto;
  background-size: cover;
  width: 80px;
  height: 80px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 4px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  background: #fff;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
  height: 20px;
  background: #ff3f40;
}
.twozi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.map {
  width: 100%;
  height: 400px;
}
.sample {
  width: 30px;
  height: 30px;
  text-align: center;
  position: absolute;
}
.sample.active img {
  width: 100%;
}

/*# sourceMappingURL=app.d60cbced.css.map*/