@import "@/styles/main.scss";



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.isIndex {
  .el-button {
    border-radius: 0px;
  }
  .magin0 {
    margin: 0px;
  }
  .row-bg {
    margin-left: 0px;
    margin-right: 0px;
  }
  font-family: "Helvetica", sans-serif;
  .siziimg {
    position: absolute;
    top: 25%;
    width: 100%;
    img {
      //         position: relative ;
      //  top:20%;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .gamstetit {
    width: 100%;
    margin: 2rem 0px;
    .en {
      font-size: 1rem;
      color: #101010;
      font-family: "Helvetica", sans-serif;
    }
    .zh {
      font-size: 1.5rem;
      color: #101010;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
  }
  .isgemscent {
    background: #e7e7e7;
  }
  .isNews {
    background: #eeeeee;
  }
  .isbj2 {
    margin-top: 50px;
    width: 100%;
    // background: url("~@/assets/bjzhes.jpg") center center no-repeat;
  }
  .triangle_left {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 10rem solid #ff3f40;
    border-bottom: 0px solid transparent;
  }
  .swiper-button-prev_q {
    background: url("~@/assets/193.png") center center no-repeat;
    left: -30px;
    right: auto;
    background-size: cover;
    width: 80px;
    height: 80px;
  }
  .swiper-button-next {
    background: url("~@/assets/194.png") center center no-repeat;
    right: -30px;
    left: auto;
    background-size: cover;
    width: 80px;
    height: 80px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 4px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  background: #fff;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  height: 20px;
  background: #ff3f40;
}
.twozi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.map {
  width: 100%;
  height: 400px;
}
.sample {
  width: 30px;
  height: 30px;
  // line-height: 40px;
  // background: rgba(0,0,0,0.5);
  // overflow: hidden;
  // box-shadow: 0 0 5px #000;
  // color: #fff;
  text-align: center;
  // padding: 10px;
  position: absolute;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
  img {
    width: 100%;
  }
}
