@import "@/styles/main.scss";















































































































// 小于768 显示手机端
.isHead {
  background: #ff4949;
  height: 60px;

  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-menu-item {
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  :hover {
    border-bottom: solid 12px #e6e6e6;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #ff4949;
  color: #fff;
}

.ispcli {
  list-style-type: none;
  float: left;
  // padding:10px;
  line-height: 60px;
  margin-top: -20px;
  font-weight: bold;
  font-family: "FZLTCHJW", sans-serif;
  font-size: 16px;
  height: 62px;
  padding: 0px 15px;
  text-align: center;
  a {
    color: #fff;
    text-decoration: none;
  }
  color: #fff;
  &:hover {
    border-bottom: solid 2px #e6e6e6;
    background: #bd3737;
  }
}
