@import "@/styles/main.scss";


























































































































































.isfooter {
  background: #383838;
  width: 100%;
  height: 200px;
  li {
    list-style-type: none;
    padding: 10px 0px;
  }
  .hezuo {
    display: inline-block;
    padding: 10px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
